/* -------------------------------------
CSS reset
Is a short set of CSS rules that resets the styling of all HTML elements to a consistent baseline so the website will look the same on all browsers
*/


* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: Nunito-Sans;
  /* background-color: #E9E7DA; */
  line-height: 1.5;
  /*The box-sizing property is used to tell the browser what the sizing properties (width and height) should include border-box.*/
  box-sizing: border-box;
  /*reset Chrome user agent styles*/
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-before: 0px;
  -webkit-padding-after: 0px;
  -webkit-padding-start: 0px;
  -webkit-padding-end: 0px;
  /*reset Firefox user agent styles*/
  -moz-margin-before: 0px;
  -moz-margin-after: 0px;
  -moz-margin-start: 0px;
  -moz-margin-end: 0px;
  -moz-padding-before: 0px;
  -moz-padding-after: 0px;
  -moz-padding-start: 0px;
  -moz-padding-end: 0px;
  /*reset Internet Explorer user agent styles*/
  -ms-margin-before: 0px;
  -ms-margin-after: 0px;
  -ms-margin-start: 0px;
  -ms-margin-end: 0px;
  -ms-padding-before: 0px;
  -ms-padding-after: 0px;
  -ms-padding-start: 0px;
  -ms-padding-end: 0px;
  /*reset Opera user agent styles*/
  -o-margin-before: 0px;
  -o-margin-after: 0px;
  -o-margin-start: 0px;
  -o-margin-end: 0px;
  -o-padding-before: 0px;
  -o-padding-after: 0px;
  -o-padding-start: 0px;
  -o-padding-end: 0px;
}

/* -------------------------------------
General styles (General Module, wrapping around the modules below)
(the styles used everywhere in the page (like the Body) or styles which are used in more than 2 of the modules below (h2, h3, h4, h5, h6, p, a, ul, li))
*/

body {
  background: url(../../images/textured-background.png) top center no-repeat fixed;
  background-size: cover;
}

a {
  transition: .2s ease;
  text-decoration: none;
  color: #5F0F4E;
  font-weight: 700;
}

a:hover {
  cursor: pointer;
  color: darkred;
}

li {
  list-style: none;
}

.strong {
  font-weight: 700;
}

input {
  border: 1px solid black;
  margin-bottom: 2px;
  background-color: white;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.title-style {
  font-family: Halant;
}

.center-align {
  text-align: center;
}

.form-title {
  text-align: left;
}

.error {
  font-size: .9em;
  color: red;
  text-align: center;
  background-color: #E9E7DA;
  border: 1px solid black;
}

button:hover {
  cursor: pointer;
  background-color: #373F27;
  color: white;
}

button {
  border-radius: 3%;
  font-family: Halant;
  background-color: #636B46;
  color: white;
  display: block; 
  margin: auto;
  padding: 6px 10px;
  font-size: 1.2em;
  border: 1px solid black;
  transition: .2s ease-in-out;
   -moz-transition: .2s ease-in-out;
   -webkit-transition: .2s ease-in-out;
}

.rbutton {
  color: white;
  background-color: darkred;
}

.rbutton:hover {
  cursor: pointer;
  background-color: #49274A;
}

/* -------------------------------------
Nav styles (Main Module)
(all the styles inside the < header > or < nav > tags)
*/

/* Nav */
.nav {
  height: 45px;
  background-color: #373F27;
  text-align: center;
  position: fixed;
  padding: 6px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px solid black;
}

.nav * {
  background-color: #373F27;
  ;
}

.nav a {
  font-size: 1.2em;
  color: white;
}

.home-icon {
  font-size: 1.2em;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* -------------------------------------
Content styles (Main Module)
(all the styles inside the < sections > or < aside > tags)
*/

/* Landing */

.landing-title {
  font-size: 2em;
}

.landing-description {
  margin: auto;
  max-width: 350px;
  text-align: center;
  padding-bottom: 20px;
}

.landing {
  background: url(../../images/landing-background.png) top center no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 20px;
}

.landing-container {
  width: 80%;
  margin: auto;
}

/* LoginForm */
.login {
  background-color: #E9E7DA;
  font-size: .9em;
  padding: 20px;
  text-align: center;
  margin: auto;
  border: 2px solid black;
  width: 250px;
  height: 340px;
}

.login-legend {
  font-size: 1.2em;
  margin: 8px 0;
}
.login-button {
  margin-top: 10px;
}

.login-info p:first-child {
  padding: 8px 0;
}

.login-info p:nth-child(3) {
  padding: 8px 0;
}


/* Registration */
.registration {
  font-size: .9em;
  height: 100vh;
  margin: auto;
  padding-top: 50px;
}

.registration-form {
  background-color: #E9E7DA;
  padding: 30px 15px;
  text-align: center;
  margin: auto;
  border: 2px solid black;
  width: 300px;
  height: 420px;
}

.registration-legend {
  font-size: 1.2em;
  margin: 8px 0;
}

.registration-button {
  margin-top: 10px;
}

.registration-form p {
  padding: 8px 0;
}

.registration-table,
.registration-table td {
  width: auto;
  border: none;
}

.registration-table input {
  width: 120px;
}

.password-rules {
  padding: 8px 0;
}

/* Room  */
.room {
  margin: 0 auto 80px;
  padding-top: 80px;
}

.room-name-title {
  color: black;
  text-decoration: underline;
}

.room-description {
  margin-bottom: 15px;
}

.lastData-title {
  text-align: center;
  background-color: white;
  color: #373F27;
  padding-top: 3px;
}

.flex-section {
  display: flex;
  flex-direction: column-reverse;
  margin: auto;
  width: 300px;
}

.right-div {
  width: 100%;
  text-align: center;
}

.right-div ul {
  margin: auto;
  text-align: left;
  width: 300px;
  list-style: none;
  padding: 20px 0;
  /* border: 1px solid black; */
}

.no-chart-data {
  width: 260px;
  height: auto;
  margin: 0px auto 10px;
  border-radius: 3px;
}

.data-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
}

.delete-room {
  margin: 30px auto 80px;
}

.data-buttons button {
  margin: 5px 15px;
}

/* Logbook */
.logbook {
  text-align: center;
  border: 1px solid black;
  border-radius: 3px;
  width: 300px;
  height: 100%;
  margin: auto;
}

.logbook-title {
  padding: 10px 0px;
  color: black;
  font-size: 1.3em;
}

.logbook-title, .log {
  background-color: white;
  padding: 5px 5px 10px 5px;
  border-bottom: 1px solid black;
}

.log > * {
  background-color: white;
}

.no-data {
  font-size: 1.5em;
  margin: 100px 0;
}

/* DataCharts */
.chart-display div {
  margin: auto;
}

.chart-display {
  width: 100%;
}

.overflow {
  max-width: 400px;
  margin: auto;
  border: 1px solid black;
  /* overflow-x: scroll; */
  overflow-y: hidden; 
}

.chart-table-div {
  margin: auto;
  width: 250px;
  text-align: left;
  margin-left: 50px;
}

.chart-table {
  padding: 0;
  border: none;
}

.chart-table-div p {
  padding: 5px 0;
  width: 200px;
  margin: auto;
  position:relative;
  right:10px;
}

.chart-table td {
  padding: 0;
  border: none;
  text-align: left;
}

.chart-legend {
  width: 200px;
  margin-left: 10px;
}

/* User */
.user {
  margin: 80px auto;
}

.user-section {
  background-color: #E9E7DA;
  padding: 15px 30px;
  text-align: center;
  margin: auto;
  border: 2px solid black;
  width: 250px;
  height: 250px;
}

.user_name-title {
  color: darkred;
  font-family: Halant;
}

h1 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.create-room-legend {
  font-size: 1.2em;
  margin: 8px 0;
}

.create-room-form input {
  width: 150px;
}

.new-room-button {
  margin-top: 10px;
}

/* ExistingRooms */
.existing-rooms {
  text-align: center;
  margin: auto;
  padding: 10px 0 80px 0;
  width: 250px;
}

.existing-rooms ul {
  list-style: none;
  margin: auto;
  width: 80%;
  padding: 0;
}

.existing-room-title {
  font-size: 1.2em;
  margin: 8px 0;
}

.existing-room-li {
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
  display: flex;
  min-height: 80px;
  background-color: white;
}

.room-div {
  text-align: center;
  margin: auto;
}

.room-div h3 {
  font-size: 1.2em;
  background-color: white;
}
.room-div p {
  color: black;
  background-color: white;
}

/* Add Data */

.add-data {
  margin: auto;
  margin-top: 80px;
  width: 300px;
}

.data-legend {
  font-size: 1.2em;
  margin: 8px 0;
}

.add-data-table,
.add-data-table td {
  border: none;
}

.add-data-form {
  font-size: .9em;
  padding: 20px;
  text-align: center;
  margin: auto;
  border: 2px solid black;
  background-color: #E9E7DA;
  height: 450px;
}

table {
  table-layout: fixed;
  width: 250px;
}
.short-input input,
.short-input td {
  width: 50px;
}

.add-data-button {
  margin: 10px auto;
}

.comments-textbox {
  background-color: white;
  border: 1px solid black;
  height: 120px;
  width: 250px;
}

/* View Data */
.view-data {
  text-align: center;
  min-height: 200px;
  margin: 80px auto;
  width: 300px;
}

h2 {
  font-size: 1.2em;
  color: #292f1d;
}

.inserted-data {
  border: 2px solid black;
  background-color: #E9E7DA;
  border-radius: 3px;
  padding: 5px;
  margin: 10px 0;
}

.inserted-data-ul {
  margin: 5px auto;
}

.inserted-data-comments {
  text-align: left;
  padding: 5px;
}

.inserted-data h2,
.inserted-data p, 
.inserted-data li,
.inserted-data span {
  background-color: #E9E7DA;
}

.view-data-table {
  margin: auto;
}

table, tr, th, td {
  border: 1px solid black;
  padding: 5px;
}

.delete-data {
  margin: 5px auto;
}

/* About  */

.about-section {
  height: 100vh;
}

.about {
  padding: 20px;
  border: 2px solid black;
  background-color: #E9E7DA;
  height: auto;
  text-align: center;
  margin: 60px auto;
  width: 85%;
}

.about h1 {
  font-size: 1.5em;
  margin-bottom: 25px;
}

/* Page-Not-Found */
.page-not-found-title {
  margin: auto;
  padding-top: 100px;
  min-height: 200px;
  width: 80%;
}


/* -------------------------------------
place all the desktop styles above this line
Responsive styles
(the very last module in the CSS, contains all the media queries and it is dealing with the compatibility with mobiles and tablets)
place all the mobile tables styles below this line
*/

@media only screen and (min-width: 1000px) {
    /* '*' */

    .about,
    .room,
    .user,
    .add-data,
    .view-data {
      margin-top: 100px;
    }

    /* Nav */
    .nav {
      padding-top: 10px;
      border-top: none;
      height: 52px;
    }
  
    .nav a {
      color: white;
      margin: 10px 50px;
      padding: 10px;
      border-radius: 5%;
      transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -webkit-transition: .2s ease-in-out;
    }
  
    .nav a:hover {
      cursor: pointer;
      color: black;
    }

  /* Landing */

  .landing {
    padding-top: 100px;
  }

  .landing-title {
    font-size: 2.5em;
  }
  
  .landing-description {
    font-size: 1.2em;
  }

  /* Registration */

  .registration {
    padding-top: 100px;
  }

  /* About */
  .about {
    width: 500px;
  }

  /* Room */
  .room {
    padding-top: 0;
    width: 95%;
  }

  .flex-section {
    width: 90%;
    flex-direction: row;
  }

  .right-div {
    width: 50%;
    margin-left: 40px;
  }

  .logbook {
    align-self: center;
    max-width: 700px;
    margin: 0 50px 0 80px;
  }

  .no-chart-data {
    width: 100%;
  }

  /* DataCharts */
  .chart-table-div {
    position:relative;
    margin:0 auto;
    left:30px;
    padding-top: 5px;
  }

  /* View-data */

  .view-data {
    text-align: center;
    margin: 80px auto;
    width: 60%;
  }

  .inserted-data {
    border: 2px solid black;
    padding: 3%;
    margin: 10px 0;
    display: flex;
  }

  .data-div {
    display: flex;
  }

  .inserted-data h2 {
    font-size: 1em;
    color: darkred;
  }

  .inserted-data-ul {
    font-size: .9em;
  }

  .delete-data {
    align-self: center;
    margin: 0;
  }
  
  .inserted-data-comments {
    background-color: white;
    border: 1px solid #373F27;
    text-align: left;
    margin: 10px 30px;
    width: 70%;
  }

  /* Page-Not-Found */
  .page-not-found-title {
    margin-top: 200px;
    font-size: 1.5em;
  }
}